import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@store/index";
import ActionCable from "actioncable";
import { toast } from "react-toastify";
import { errorHandler } from '@utils/errorHandler';
import instance from "@lib/api";
import 'react-tooltip/dist/react-tooltip.css';

let webSocketUrl;
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  webSocketUrl = "wss://prod-chat.nattyhatty.com/nh_chat_channel";
  // webSocketUrl = "ws://dev2-chat.nattyhatty.com/nh_chat_channel";
} else if (process.env.REACT_APP_ENVIRONMENT === 'sandbox'){
  webSocketUrl = "ws://dev2-chat.nattyhatty.com/nh_chat_channel";
} else {
  webSocketUrl = "ws://dev2-chat.nattyhatty.com/nh_chat_channel";
}

const cable = ActionCable.createConsumer(webSocketUrl);
const get_local_token = () => {
  var userData = store.getState();
  const session = userData?.user?.user?.user?.session;
  if (session) return session;
}
store.subscribe(get_local_token)
const root = ReactDOM.createRoot(document.getElementById("root"));
instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = `Bearer ${get_local_token()}`;
    return config;
  },
  (error) => {
    console.log(error);
    // Do something with request error
    return Promise.reject(function () {
      // 	Utils.handleAjaxError(error)/
    });
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    toast.error(errorHandler(error));
    // Do something with response error
    console.log(error);
    if (error?.response?.status === 500) {
      console.log('xxxxxxxxxx Server call failed xxxxxxxxxxxx');
    }
    if (error?.response?.status === 401) {
      console.log('xxxxxxxxxx unauthorized access xxxxxxxxxxxx');
    }
    if (error?.response?.status === 401) {
      console.log('xxxxxxxxxx user not found xxxxxxxxxxxx');
    }
    return Promise.reject(function () {
      //handle error using toast or redirection
    });
  }
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App cable={cable} />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);
